import React from 'react'
import {
  Container,
  Link,
  Section,
  Seo,
} from '@components'
import { OfferContent } from '../../../components/site/offer'
import { PageLayout } from '../../../components/site/layout/page-layout'
import { ConsultBanner } from '../../../components/site/product'
import { ApproovLogo } from '../../../components/site/approov'

const ConsultationConfirmationPage = ({ location }) => {
  const offer = 'consult'

  return (
    <PageLayout pathname={location.pathname}>

      <ConsultBanner title="Mobile Security Consultation" />

      <OfferContent offer={offer}>
        <Section>
          <Container className="pt-8 container-lg">
            <div className="flex items-center">
              <div className="flex w-4/12">
                <ApproovLogo className="w-full h-full" />
              </div>
              <div className="w-9/12 ml-16">
                <p>Thank you for requesting a consultation with us!</p>
                <p>
                  We will contact you to schedule your call at a time which is
                  convenient for you.
                </p>
                <p>
                  If you do not hear from us within 24 hours or this is an
                  emergency, please let us know at the{' '}
                  <Link href="https://approov.io/info/contact">contact us</Link> page, and we will do
                  our best to respond quickly.
                </p>
              </div>
            </div>
          </Container>
        </Section>
      </OfferContent>
    </PageLayout>
  )
}

export default ConsultationConfirmationPage
